const socialInfo = {
  facebook: "https://www.facebook.com/marjcagency",
  instagram: "https://www.instagram.com/marjc.ch",
  github: "https://github.com/MarJC5",
  twitter: "https://twitter.com/jeanchristio",
  linkedin: "https://www.linkedin.com/in/jean-christio-martin-385574111/",
  linkedinPro: "https://www.linkedin.com/company/marjc-agency",
  contact: {
    email: "admin@marjc.ch"
  }
}

export { socialInfo };