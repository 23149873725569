import React from "react"
import { socialInfo } from "../../js/utils"
import Logo from "../partials/svg/logo"

// Markup
const Footer = () => {
  return (
    <footer className="main-page-footer">
      <h4>
        &copy; {new Date().getFullYear()} {` `}
        <span>
        &mdash; Martin Jean-Christio
        </span>
      </h4>
      <ul>
        <li>
          <a href={socialInfo.github}>
            GitHub
          </a>
        </li>
        <li>
          <a href={socialInfo.twitter}>
            Twitter
          </a>
        </li>
        <li>
          <a href={socialInfo.linkedin}>
            LinkedIn
          </a>
        </li>
        <li>
          <a href={socialInfo.instagram}>
            Instagram
          </a>
        </li>
        <Logo url="/" styleClass="main-logo" text="MARJC"/>
      </ul>
    </footer>
  )
}

export default Footer