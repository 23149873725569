import React, {useEffect} from "react"

// Utilities
import anime from 'animejs/lib/anime.es.js'
import { Link } from "gatsby";

const Morph = ({ color }) => {    
    useEffect(() => {
        const morph = document.querySelector("#morph")
        const menu = document.querySelector(".btn-menu")
        const links = document.querySelector("#morph-menu-links")
        const btnOpen = document.querySelector(".btn-open")
        const btnClose = document.querySelector(".btn-close")
        const morphOut = document.querySelector(".morph-2")
        const morphIn = document.querySelector(".morph")


        // SVG MORPH
        const morphingIn = anime({
        targets: '.morph',
        d: [
            {value: 'M4.00003 0.504455L-254.5 0.50415C-254.389 16.0822 270.969 146.002 989 140C1699.7 137.513 2149.11 16.3419 2149 0.504204L4.00003 0.504455Z'},
            {value: 'M4.00003 0.504455L-254.5 0.50415C-254.389 16.0822 264.469 536.502 982.5 530.5C1693.2 528.013 2149.11 16.3419 2149 0.504204L4.00003 0.504455Z'},
            {value: 'M4.00003 0.504455L-254.5 0.50415C-254.389 16.0822 229.969 1085 948 1079C1658.7 1076.51 2149.11 16.3419 2149 0.504204L4.00003 0.504455Z'},
            {value: 'M4.00003 0.504455L-254.5 0.50415C-254.389 16.0822 266.469 2849 984.5 2843C1695.2 2840.51 2149.11 16.3419 2149 0.504204L4.00003 0.504455Z'},
        ],
        easing: 'easeInOutQuad',
        duration: 800,
        loop: false,
        autoplay: false
        })

        const morphingOut = anime({
            targets: '.morph-2',
            d: [
              {value: 'M4.00003 0.504455L-254.5 0.50415C-254.389 16.0822 266.469 2849 984.5 2843C1695.2 2840.51 2149.11 16.3419 2149 0.504204L4.00003 0.504455Z'},
              {value: 'M4.00003 0.504455L-254.5 0.50415C-254.389 16.0822 266.469 2849 984.5 2843C1695.2 2840.51 2149.11 16.3419 2149 0.504204L4.00003 0.504455Z'},
              {value: 'M4.00003 0.504455L-254.5 0.50415C-254.389 16.0822 229.969 1085 948 1079C1658.7 1076.51 2149.11 16.3419 2149 0.504204L4.00003 0.504455Z'},
              {value: 'M4.00003 0.504455L-254.5 0.50415C-254.389 16.0822 264.469 536.502 982.5 530.5C1693.2 528.013 2149.11 16.3419 2149 0.504204L4.00003 0.504455Z'},
              {value: 'M4.00003 0.504455L-254.5 0.50415C-254.389 16.0822 270.969 146.002 989 140C1699.7 137.513 2149.11 16.3419 2149 0.504204L4.00003 0.504455Z'},
              {value: 'M3.99982 1.50459L-254.5 1.50429C-254.389 17.0823 243.469 -147.998 961.5 -154C1672.2 -156.487 2149.11 17.342 2149 1.50434L3.99982 1.50459Z'}
            ],
            easing: 'easeInOutQuad',
            duration: 800,
            loop: false,
            autoplay: false
        })

        menu.addEventListener('click', (e) => {
            if (menu.classList.contains('close')) {
                menu.classList.toggle("close")
                btnOpen.classList.toggle("hidden")
                btnClose.classList.toggle("hidden")
                morphIn.style.fill = "none"
                morphOut.style.fill = color
                morphingOut.play()
                setTimeout(() => {
                    morph.style.zIndex = -5 
                }, 800);
                setTimeout(() => {
                    links.style.display = "none"
                }, 500);
            } else {
                menu.classList.toggle("close")
                btnOpen.classList.toggle("hidden")
                btnClose.classList.toggle("hidden")
                morphIn.style.fill = color
                morphOut.style.fill = "none"
                setTimeout(() => {
                    links.style.display = "block"
                }, 500);
                morph.style.zIndex = 5
                morphingIn.play()
            }
        })
     }, [color]);
    
    return(
        <>
            <div id="morph">
                <svg id="morph-menu" width="100%" height="110%" viewBox="0 0 1920 1080" preserveAspectRatio="none">
                    <path className="morph" d="M3.99982 1.50459L-254.5 1.50429C-254.389 17.0823 243.469 -147.998 961.5 -154C1672.2 -156.487 2149.11 17.342 2149 1.50434L3.99982 1.50459Z" fill={color} opacity="1"></path>
                    <path className="morph-2" d="M4.00003 0.504455L-254.5 0.50415C-254.389 16.0822 266.469 2849 984.5 2843C1695.2 2840.51 2149.11 16.3419 2149 0.504204L4.00003 0.504455Z" fill="none" opacity="1"></path>
                </svg>

                <ul id="morph-menu-links">
                    <li>
                        <h1>
                            <Link to="/" activeStyle={{ opacity: 0.3 }}>
                                accueil
                            </Link>
                        </h1>
                    </li>
                    <li>
                    <h1>
                        <Link to="/about" activeStyle={{ opacity: 0.3 }}>
                            whoami
                        </Link>
                        </h1>
                    </li>
                    <li>
                    <h1>
                        <Link to="/portfolio" activeStyle={{ opacity: 0.3 }}>
                            portfolio
                        </Link>
                        </h1>
                    </li>
                    <li>
                    <h1>
                        <Link to="/contact" activeStyle={{ opacity: 0.3 }}>
                            contact
                        </Link>
                        </h1>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Morph
