import React, {useEffect} from "react"
import PropTypes from "prop-types"

// Components
import Footer from "./footer"

// Style
import "../../stylesheets/main.scss"
import Menu from "../partials/menu"
import Morph from "../partials/morph"

// Markup
const Layout = ({ children, styleClass, mainColor }) => {

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    onResize();
    window && window.addEventListener('resize', onResize);
    return () => {
        window && window.removeEventListener('resize', onResize);
    }
  }, []);

  const onResize = e => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    const morph = document.querySelector("#morph")
    const menu = document.querySelector(".btn-menu")

    if (!menu.classList.contains('close')) {
      morph.style.zIndex = -5
    }
  }, []);

  return (
    <>
        <Morph color={mainColor}/>
        <main className={`main-content ${styleClass}`}>
            <Menu color={mainColor} styleClass="btn-menu"/>
            {children}
            <Footer/>
        </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout