import React from "react"

// Markup
const Logo = ({ url, styleClass, text, color}) => {
  return (
    <div className={styleClass}>
       <a href={url}>
       <svg fill={color} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 2000 2000">
<g transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)">
	<path d="M9716.7,16155.6c-44.8-37.8-136.9-99.1-205.4-136.9c-68.5-37.8-170-92.1-221.9-120.4c-54.3-28.3-103.9-47.2-115.7-42.5
		c-9.4,7.1-23.6,56.7-33,108.6c-9.4,54.3-37.8,118-63.7,144c-44.8,44.8-47.2,44.8-127.5-2.4c-61.4-37.8-94.4-44.8-127.5-26
		c-30.7,16.5-122.7,0-344.6-61.4c-167.6-44.8-311.6-89.7-323.4-101.5c-9.4-9.4-37.8-54.3-59-99.1l-40.1-80.3h-198.3
		c-219.5,0-231.3-9.4-195.9-136.9l23.6-82.6l-181.8-14.2c-99.1-9.4-221.9-18.9-273.8-21.2c-103.9-4.7-271.5-96.8-271.5-146.4
		c0-16.5,42.5-120.4,94.4-226.6c110.9-229,118-306.9,35.4-387.1c-92.1-94.4-188.8-73.2-342.3,73.2
		c-155.8,148.7-323.4,179.4-465,89.7c-75.5-47.2-73.2-181.8,4.7-283.3c49.6-66.1,54.3-85,33-148.7c-14.2-40.1-40.1-73.2-56.7-73.2
		c-18.9,0-103.9,37.8-193.6,85l-160.5,85l-229-243.1c-127.5-132.2-231.3-252.6-231.3-269.1c0-14.2,26-40.1,56.7-54.3
		c40.1-16.5,59-51.9,70.8-118c14.2-94.4-14.2-217.2-49.6-217.2c-11.8,0-66.1,33-125.1,70.8s-118,70.8-132.2,70.8
		c-35.4,0-174.7-214.8-174.7-271.5c0-61.4-136.9-290.3-191.2-318.7c-61.4-33-316.3-545.3-290.3-580.7
		c11.8-14.2,56.7-70.8,99.1-127.5c80.3-106.2,99.1-221.9,42.5-243.1c-18.9-7.1-73.2-7.1-118,0c-132.2,21.2-191.2-4.7-219.5-99.1
		c-23.6-77.9-21.2-87.3,54.3-162.9c44.8-44.8,80.3-103.9,80.3-129.8c0-56.7-77.9-96.8-188.8-96.8c-59,0-68.5-9.4-82.6-99.1
		c-16.5-94.4-14.2-101.5,80.3-177c110.9-94.4,120.4-139.3,37.8-177c-54.3-23.6-103.9-106.2-141.6-231.3c-9.4-33-37.8-85-61.4-115.7
		c-66.1-82.6-56.7-172.3,26-250.2c94.4-92.1,92.1-158.2-14.2-238.4l-85-66.1l9.4-226.6c7.1-210.1,11.8-233.7,73.2-306.9
		c51.9-61.4,63.7-94.4,54.3-146.4c-7.1-40.1,4.7-118,33-191.2c59-155.8,59-233.7,0-288c-59-54.3-59-92.1,2.4-177
		c37.8-49.6,47.2-87.3,35.4-146.4c-9.4-49.6,4.7-139.3,40.1-254.9l54.3-177l103.9-7.1l106.2-7.1l80.3,136.9
		c344.6,590.1,960.7,1149.6,1553.2,1413.9c729.4,328.1,1638.2,387.1,2426.6,162.9c297.4-85,783.7-330.5,1036.3-524
		c115.7-87.3,229-174.7,252.6-191.2c35.4-28.3,59-16.5,191.2,89.7c436.7,354.1,878.1,568.9,1413.9,689.3
		c214.8,47.2,306.9,54.3,753,54.3c406,0,547.6-9.4,705.8-44.8c670.4-146.4,1206.2-439.1,1692.5-923
		c144-141.6,295.1-302.1,332.8-354.1l73.2-92.1l160.5,54.3c259.7,89.7,288,134.5,198.3,311.6c-59,113.3-44.8,174.7,49.6,224.2
		c110.9,56.7,132.2,129.8,66.1,219.5c-68.5,94.4-49.6,134.5,103.9,212.4c59,30.7,115.7,73.2,122.7,92.1
		c18.9,51.9-49.6,144-125.1,160.5c-122.7,26-141.6,35.4-203,82.6c-125.1,99.1-49.6,262,177,375.3
		c127.5,66.1,186.5,125.1,186.5,186.5c0,14.2-30.7,61.4-70.8,103.9c-82.6,96.8-87.3,120.4-23.6,198.3c61.4,77.9,59,96.8-18.9,221.9
		c-66.1,101.5-66.1,108.6-33,200.6c42.5,115.7,28.3,167.6-54.3,198.3c-33,11.8-66.1,42.5-75.5,63.7c-9.4,23.6-44.8,80.3-82.6,125.1
		c-68.5,82.6-85,151.1-42.5,177c35.4,21.2,28.3,108.6-11.8,153.4c-28.3,33-30.7,59-9.4,167.6c26,122.7,23.6,134.5-56.7,309.2
		c-73.2,160.5-96.8,188.8-219.5,266.7l-136.9,87.3l51.9,42.5c28.3,23.6,51.9,47.2,51.9,54.3c0,7.1-73.2-7.1-165.2-30.7
		c-89.7-23.6-165.2-35.4-165.2-28.3c0,47.2,68.5,264.4,103.9,330.5l42.5,80.3l-87.3,129.8c-49.6,75.5-99.1,125.1-115.7,118
		c-16.5-7.1-89.7-18.9-162.9-28.3c-165.2-21.2-174.7,9.4-28.3,96.8c59,33,106.2,68.5,106.2,77.9c0,9.4-37.8,61.4-85,115.7
		c-63.7,75.5-106.2,103.9-177,113.3c-120.4,21.2-155.8,63.7-122.7,151.1c23.6,63.7,18.9,75.5-120.4,207.7
		c-99.1,94.4-203,167.6-328.1,224.2c-172.3,80.3-219.5,132.2-155.8,170c11.8,9.4-30.7,56.7-101.5,110.9
		c-103.9,77.9-136.9,89.7-200.6,77.9c-113.3-21.2-205.4,37.8-283.3,179.4c-51.9,94.4-94.4,136.9-198.3,193.6l-132.2,73.2l-70.8-51.9
		c-110.9-80.3-141.6-68.5-181.8,73.2l-37.8,125.1l-212.4,87.3c-115.7,47.2-226.6,87.3-243.1,87.3c-18.9,0-68.5,18.9-113.3,40.1
		c-51.9,28.3-125.1,40.1-203,37.8c-96.8-2.4-132.2,4.7-155.8,37.8c-44.8,66.1-191.2,99.1-247.9,61.4c-37.8-28.3-54.3-26-129.8,28.3
		c-51.9,35.4-120.4,61.4-172.3,63.7c-82.6,2.4-94.4-4.7-160.5-122.7c-68.5-118-77.9-125.1-144-110.9
		c-37.8,9.4-122.7,18.9-188.8,21.2c-226.6,11.8-292.7,35.4-330.5,120.4c-42.5,101.5-129.8,158.2-269.1,172.3
		C9811.2,16226.4,9787.6,16219.3,9716.7,16155.6z"/>
	<path d="M7169.8,9952.2C6511.2,9846,5914,9522.6,5425.4,9008c-424.9-448.5-656.2-889.9-786-1496.6
		c-47.2-210.1-49.6-325.7-59-1930.9c-9.4-1595.7-7.1-1711.4,30.7-1753.8c37.8-42.5,80.3-44.8,753-44.8c542.9,0,719.9,7.1,736.5,30.7
		c16.5,16.5,30.7,651.5,40.1,1673.6c18.9,1812.9,14.2,1772.7,172.3,2096.1c63.7,132.2,134.5,224.2,288,377.7
		c224.2,224.2,422.5,344.6,686.9,413.1c200.6,54.3,576,54.3,779,2.4c516.9-132.2,939.5-545.3,1104.7-1083.5
		c33-106.2,37.8-354.1,44.8-1786.9c7.1-1515.4,11.8-1666.5,47.2-1692.5c51.9-40.1,1402.1-44.8,1472.9-7.1
		c42.5,23.6,44.8,68.5,44.8,1614.6c0,1772.7,4.7,1829.4,151.1,2126.8c210.1,417.8,568.9,717.6,1005.6,830.9
		c203,51.9,576,49.6,779-4.7c259.7-68.5,462.7-188.8,684.5-413.1c153.4-151.1,224.2-243.1,288-377.7
		c89.7-181.8,160.5-424.9,160.5-559.4c0-148.7-2.4-148.7,788.4-148.7c521.7,0,719.9,7.1,741.2,28.3c61.4,61.4,14.2,557.1-82.6,889.9
		c-141.6,491-448.5,972.5-873.4,1371.4c-618.4,578.3-1364.4,852.1-2225.9,819.1c-786-30.7-1418.7-292.7-1992.3-830.9
		c-92.1-85-184.1-153.4-210.1-153.4c-26,0-134.5,85-243.1,186.5c-398.9,377.7-838,609-1402.1,736.5
		C8071.5,9985.2,7471.9,10001.8,7169.8,9952.2z"/>
	<path d="M7306.7,8023.7c-396.6-136.9-712.9-488.6-795.5-885.2c-16.5-75.5-26-406-26-830.9v-705.8l266.7-177
		c325.7-217.2,720-424.9,1078.7-566.5c276.2-108.6,757.7-254.9,939.5-285.6l99.1-16.5v1260.5c0,762.4-9.4,1298.3-26,1362
		c-44.8,203-141.6,363.5-325.7,545.3c-264.4,262-486.3,354.1-852.1,354.1C7519.1,8078,7422.3,8061.4,7306.7,8023.7z"/>
	<path d="M12004.1,8042.6c-186.5-49.6-325.7-129.8-479.2-276.2c-179.4-165.2-283.3-321-349.4-521.7
		c-47.2-139.3-49.6-198.3-59-1418.7l-7.1-1269.9l113.3,16.5c181.8,26,656.2,170,951.3,285.6c524,207.7,1071.7,528.8,1534.3,897
		c212.4,170,779,727,779,764.8c0,14.2-127.5,23.6-318.7,23.6c-269.1,0-335.2,7.1-408.4,44.8c-155.8,80.3-226.6,195.9-250.2,420.2
		c-33,297.4-144,519.3-368.2,738.8c-233.7,229-495.7,332.8-838,328.1C12200,8075.6,12065.4,8059.1,12004.1,8042.6z"/>
	<path d="M13873.6,5295c-14.2-28.3-23.6-306.9-23.6-741.2c0-642.1,2.4-696.3,42.5-731.8c37.8-35.4,125.1-40.1,753-40.1
		c656.2,0,710.5,2.4,745.9,42.5c35.4,37.8,40.1,125.1,40.1,734.1c0,429.6-9.4,708.1-23.6,736.5c-23.6,42.5-56.7,44.8-767.2,44.8
		S13897.2,5337.4,13873.6,5295z"/>
</g>
</svg>
        <span>{text}</span>
       </a>
    </div>
  )
}
export default Logo